export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/(auth)/admin/settings": [5,[2]],
		"/(auth)/admin/users": [6,[2]],
		"/check-email": [19],
		"/(auth)/dashboard": [7,[2]],
		"/link-service": [20],
		"/link-service/[id]": [21],
		"/login": [22],
		"/product-demo": [23],
		"/product-demo/[id]": [24],
		"/register": [25],
		"/register/success": [26],
		"/settings": [27],
		"/(auth)/superadmin": [8,[2,3]],
		"/(auth)/superadmin/brukere": [9,[2,3]],
		"/(auth)/superadmin/firmaer": [10,[2,3]],
		"/(auth)/superadmin/firmaer/[id]": [11,[2,3]],
		"/(auth)/superadmin/firmaer/[id]/brukere": [12,[2,3]],
		"/(auth)/superadmin/logs": [13,[2,3]],
		"/(auth)/superadmin/logs/viewer": [14,[2,3]],
		"/(auth)/superadmin/logs/[serviceId]": [15,[2,3]],
		"/(auth)/superadmin/services": [16,[2,3]],
		"/(auth)/superadmin/sql": [17,[2,3]],
		"/(auth)/superadmin/sql/[serviceId]": [18,[2,3]],
		"/theme-editor": [28]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';